import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useScript from "../service/useScript"

const Inpatient = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const inpatient = Array.isArray(edges) ? edges[0] : [];
    const metaTag = []

    const data = inpatient?.node?.relationships?.field_component_type;


    const bannerData = data[0];
    const bannerTitle = bannerData?.title || "";
    const bannerSubtitle = bannerData?.subtitle || "";
    const bannerImage = bannerData?.relationships?.bannerImage?.uri?.url
    const bannerImageAlt = bannerData?.bannerImage?.alt.length > 0 ? bannerData?.bannerImage?.alt : "Hinduja Hospital "

    // const visiting = data[2];
    // const visitingTitle = visiting?.field_title;
    // const visitingDescription = visiting?.text?.processed;


    const accordData = data[1];
    const accordian = accordData?.relationships?.components;

    useScript("/assets/js/inpatient.js")

    const scrollView = (id) => {
        if (id) {
            document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "center" })
        }

    }



    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/services.css", "/assets/css/accessibility.css"],
                    }}
                    tags={metaTag}
                />
                <main className="innerpage pt-first-section">
                    <section class="inner_common_banner">
                        <img src={bannerImage} alt={bannerImageAlt} className="banner_img lazyload" />
                        <div className="banner_content text_left text_dark">
                            <div className="container">
                                <h1>{bannerTitle.length > 0 ? bannerTitle : "Your Hospital Admission - Inpatients"}</h1>
                                <p className="subhead" dangerouslySetInnerHTML={{ __html: bannerSubtitle.length > 0 && bannerSubtitle }} />
                            </div>
                        </div>
                    </section>
                    <section className="speciality_section section-bg section-py">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    {(accordian && accordian instanceof Array) &&
                                        <div className="accordion_container">
                                            <div
                                                id="accordion"
                                                role="tablist"
                                                className="common_accordion specialty_accordion"
                                            >
                                                {
                                                    Array.isArray(accordian) && accordian.map((el, index) => {
                                                        if (el.__typename === "paragraph__image_with_title_and_description") {
                                                            return (
                                                                <div className="card">
                                                                    <div
                                                                        className="card-header"
                                                                        role="tab"
                                                                        id={`heading_inpatient0${index + 1}`}
                                                                    >
                                                                        <h5 className="mb-0">
                                                                            <a
                                                                                data-toggle="collapse"
                                                                                href={`#inpatient_0${index + 1}`}
                                                                                aria-expanded="false"
                                                                                aria-controls="inpatient_01"
                                                                                id={index + 1}
                                                                            >
                                                                                {el?.title}
                                                                            </a>
                                                                        </h5>
                                                                    </div>
                                                                    <div
                                                                        id={`inpatient_0${index + 1}`}
                                                                        class="collapse show"
                                                                        role="tabpanel"
                                                                        aria-labelledby="heading_inpatient01"
                                                                        data-parent="#accordion"
                                                                    >
                                                                        <div class="card-body">
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <figure>
                                                                                        <img
                                                                                            src={el?.relationships?.image?.uri?.url}
                                                                                            alt={el?.image?.alt}
                                                                                        />
                                                                                    </figure>
                                                                                </div>
                                                                                <div class="col-md-6" dangerouslySetInnerHTML={{ __html: el?.description?.processed }} />
                                                                            </div>
                                                                            <div dangerouslySetInnerHTML={{ __html: el?.field_text?.processed }} />
                                                                            <figure>
                                                                                <img
                                                                                    src={el?.relationships?.image?.uri?.url}
                                                                                    alt={el?.image?.alt}
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div class="card">
                                                                    <div
                                                                        class="card-header"
                                                                        role="tab"
                                                                        id={`heading_inpatient0${index + 1}`}
                                                                    >
                                                                        <h5 class="mb-0">
                                                                            <a
                                                                                class="collapsed"
                                                                                data-toggle="collapse"
                                                                                href={`#inpatient_0${index + 1}`}
                                                                                aria-expanded="false"
                                                                                aria-controls="inpatient_02"
                                                                                id={index + 1}
                                                                            >
                                                                                {el?.field_title}
                                                                            </a>
                                                                        </h5>
                                                                    </div>
                                                                    <div
                                                                        id={`inpatient_0${index + 1}`}
                                                                        class="collapse"
                                                                        role="tabpanel"
                                                                        aria-labelledby="heading_inpatient02"
                                                                        data-parent="#accordion"
                                                                    >
                                                                        <div class="card-body" dangerouslySetInnerHTML={{ __html: el?.text?.processed }} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* <div className="col-md-4">
                                    <div
                                        className="visiting_hours_box"
                                        style={{
                                            background: "#fff",
                                            borderRadius: "20px",
                                            boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.15)",
                                            padding: "20px",
                                        }}
                                    >
                                        <h2 className="section-heading">{(visitingTitle && visitingTitle.length > 0) ? visitingTitle : "Visiting Hours"}</h2>
                                        <p dangerouslySetInnerHTML={{ __html: (visitingDescription && visitingDescription.length > 0) ? visitingDescription : "" }} />
                                        <p>
                                            <a href="#" class="btn btn-primary">
                                                Download operational timing
                                            </a>
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query patientCareInpatient {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/inpatient$/"}}}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
          }
          relationships {
            field_component_type {
              __typename
              ...ParagraphBanner
              ...ParagraphTitleAndDescription
              ...ParagraphSectionInPatient
            }
          }
        }
      }
    }
  }
  
  fragment ParagraphSectionInPatient on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphImageWithTitleAndDescriptionInPatient
        ...ParagraphTitleAndDescriptionInPatient
      }
    }
  }
  
  fragment ParagraphTitleAndDescriptionInPatient on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
    field_cta_link {
      title
      uri
    }
  }
  
  fragment ParagraphImageWithTitleAndDescriptionInPatient on paragraph__image_with_title_and_description {
    id
    title: field_title
    description: field_content {
      processed
    }
    image: field_image {
      alt
    }
    alignment: field_alignment
    relationships {
      image: field_image {
        id
        uri {
            value
            url
        }
      }
    }
    field_text {
      processed
    }
  } 
`
export default Inpatient